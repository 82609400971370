.captain-verify-phone-span {
    border:1px solid $main-color;
    border-radius:4px;
    padding:0.5rem;
    display:block;
    margin-right:0.3rem;
}

.col-pick-img {
    position:relative;
    .pick-image {
        position:absolute;
        top:0;
    }
}

.form-field-badge {
    font-size:1.1em;
    font-weight: 300;
    border:1px solid #cecece;
}

.account-tabs .tab-pane {
    padding:1rem !important;
    background-color: white;
    border:1px solid #f1f1f1;
}

.tabs-landing {
    .tab-pane {
        padding:1.5rem 3rem;
        background-color: white;
        border:1px solid #f0f0f0;
    }
}

.preview-editor {
    border:1px solid #cecece;
    border-radius:4px;
    cursor:text;
    // padding:1rem;
}

.field-choice-legend {
    border:1px solid #CECECE;
    border-radius:6px;
    padding:0.7rem;   
}

.company-account-item.active {
    a {
        color: white !important;
    }
    
}

.btn-edit-editor {
    position:absolute;
    left:2rem;
    background-color: rgba(7, 39, 99, 0.7);
    border-radius:4px;
}

.btn-edit-editor:hover {
    background-color: $main-blue;
}

label input:checked+.d-flex {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
}