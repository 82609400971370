@import './public.css';

$bg: #262626;
$border: #151515;

#btn-lp-draft {
    &:hover {
        span:nth-child(2) {
            display: none;
        }

        &::after {
            content: 'Supprimer ?'
        }
    }
}

#modal-wrapper {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.modal-row-tools-wrapper {
    position: absolute;
    right: 0;
    top: 0;
}

#tools {
    padding: 0;
    min-height: 93vh;
    border-right: 4px solid $border;
    background-color: $bg;
    overflow: scroll;

    h6 {
        color: white;
    }

    #tools-title {
        color: white;
        text-align: center;
        background-color: black;
        padding: 1rem;
        margin: 0;
    }

    #tools-wrapper {
        padding: 0 0.5rem;
    }

    .design-accordion {
        margin-top: 0.5rem;

        .accordion-button {
            font-size: 0.95rem;
            background-color: $bg;
            color: white;
        }
    }

    .validate-btn {
        background-color: transparent !important;
        color: white !important;
        border: 1px solid white !important;
        width: 100%;
        padding: 1rem !important;
        margin-top: 0.5rem;
    }
}

.row-container {
    .row-actions {
        position: absolute;
        left: 2px;
        top: 2px;
        padding: 0.5rem;
        background-color: #cecece74;
        visibility: hidden;
        border-radius: 3px;
        transition: all 0.10s ease-in-out;
        opacity: 0;
    }
}

.row-container:hover {
    .row-actions {
        visibility: visible;
        opacity: 1;
    }

    .row-actions:hover {
        background-color: #cecece;
    }
}

.lp-column {
    border: 1px solid green;
}

.content-wrapper {
    position: relative;
    min-height: 30px;

    .move-content-wrapper {
        background-color: #cececee2;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 4px;
        padding: 1px;
        border: 1px solid #cecece;

        span {
            font-size:1.2rem;
        }
    }

    .delete-content-button-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #cecece74;

        span {
            font-size:1.2rem;
        }
    }

    .move-content-wrapper .btn:hover,
    .delete-content-button-wrapper .btn:hover {
        background-color: darkblue;
        color: white !important;
    }
}

.edit-column-wrapper {
    position: relative;

    .edit-column {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        background-color: #cececeb5;
        visibility: hidden;
        opacity: 0;
        transition: all 0.1s ease-in;
    }
}

.column-wrapper {
    border: 1px solid rgba(0, 0, 0, 0);
}

.column-wrapper:hover {
    border: 1px solid #cecece;
    cursor: pointer;
}

.small-number-input {
    width: 60px;
}

.row-design-popover {
    z-index: 8000;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.image-selector {
    width: 100%;
    height: 150px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.field-actions {
    border: 1px solid #cecece;
    border-radius: 4px;
    padding: 1px;
    background-color: #ececec;

    .btn:hover {
        background-color: darkblue;
        color: white;
    }
}

#form-wrapper {
    //margin-top: 2rem;

    #form-title {
        margin-top: -2.5rem;
    }

    #form-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

input.no-border {
    border: none;
    border-bottom: 1px solid #cecece;
}

.design-title {
    //background-color: rgb(47, 47, 47);
    color: #5c5c5c;
    font-weight: 500;
    border-top: 1px solid #ececec2f;
    margin-top: 1rem;
    color: white;
}

.picker-existing-image {
    width: fit-content;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 25%;
    text-align: center;
}

.field {
    .field-edit {
        display: none;
        visibility: hidden;
        opacity: 0;
        transition: all 0.1s ease-in;
    }
}

.field:hover {
    .field-edit {
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

.field-actions {
    background-color: #ececec;
    padding: 0.4rem;
}


.design-controller {
    margin-top: 2rem;
    border: 1px solid #cecece;
    border-radius: 4px;
    background-color: white;
    padding: 0.5rem;

    label {
        color: #4d4d4d;
    }

    .title {
        background-color: white;
        color: $bg;
        border: 1px solid #cecece;
        text-align: center;
        padding: 0.3rem;
        margin-top: -1.7rem;
        margin-bottom: 0.5rem;
        display: block;
        border-radius: 4px;
    }

    .input-group-text {
        font-size: 0.7rem !important;
    }

    .bi {
        font-size: 1rem;
    }
}


.add-content-group {
    .btn {
        padding: 0.1rem 0.5rem;
        background-color: white;
        color: $bg;
    }

    .bi {
        font-size: 1.3rem;
    }

    div {
        font-size: 0.8rem;
    }
}

.focus-animation {
    background-image: linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 3px, 15px 3px, 3px 15px, 3px 15px;
    background-position: left top, right bottom, left bottom, right top;
    animation: border-dance 1s infinite linear;
    padding: 3px;
}

@keyframes border-dance {
    0% {
        background-position: left top, right bottom, left bottom, right top;
    }

    100% {
        background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
    }
}

#landing-v2-nav {
    padding: 0.8rem 1rem;
    background-color: $bg;
    border-bottom: 2px solid $border;

    .btn {
        color: white;
        background-color: transparent;
    }

    .nav a {
        color: white;
        font-weight: 300;
        font-size: 1rem;
    }

    .nav-pills .nav-link.active {
        background-color: white;
        color: $bg;
    }
}

#infos {
    .info-title {
        background-color: $bg;
        color: white;
        border-radius: 4px;
    }
}

#containers-menu {
    margin: 1rem 0;
    position: relative;

    #add-wrapper {
        background-color: $bg;
        padding: 1rem;
        color: white;
        border-radius: 4px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

blockquote {
    margin: 1rem 0;
    padding-left: 1.5rem;
    border-left: 5px solid #000000;
    /* Just change the color value and that's it*/
}

#form-actions-wrapper {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #cecece;
}

label.radio input:checked+.d-flex {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
}

.cursor-pointer {
    cursor: pointer;
}

.tox-tinymce iframe {
    background-color: transparent !important;
}

.design-control {
    margin-top: 1.2rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    label {
        position: absolute;
        margin: 0;
        padding: 0 4px;
        z-index: 4;
        transform: translate(5px, -55%);
        background-color: white;
        font-weight: 500;
    }
}

.label-design {
    button {
        padding: 0.2rem 0.5rem;
    }

    span::before {
        font-size: 2rem;
    }
}

.rotate-icon {
    &::before {
        transform: rotate(90deg);
    }
}

.picker {
    position: relative;
}

.swatch {
    width: 56px;
    height: 28px;
    border-radius: 8px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.popover {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);

    input {
        margin:auto;
        width:100%;
        text-align: center;
        border:none;
        padding:0.4rem;
        border-radius:4px;
    }
}