#app-navigation {
    background-color: $main-blue;
    height:100%;

    #app-logo {
        margin-top:1rem;
    }

    .accordion {
        margin-top:3rem;
    }

    .accordion-body {
        padding:0;  
    }

    a {
        text-decoration: none;
    }

    img {
        max-width:200px;
    }

    .accordion-item {
        background-color: transparent;
        border:none;
    }

    .accordion-header {
        .accordion-button.collapsed {
            background-color: transparent;
            color:white;
        }

        .accordion-button {
            background-color: $menu-focus;
            border-radius: 0;
            //padding-right:0.7rem;
            color:white;
        }

        .accordion-button {
            font-size:0.8rem;

            .bi {
                font-size:1.5rem;
                margin-right:0.5rem;
            }
        }

        button::after {
            filter: invert(100%) sepia(0%) saturate(7%) hue-rotate(189deg) brightness(102%) contrast(100%);
        }

        button:focus {
            box-shadow: none;
        }
    }

    .accordion-body {
        background-color: #00000015;
    }

    .menu-item {
        a {
            text-decoration: none;
            color:white;
            display:flex;
            align-items: center;
            //padding:0.5rem 3rem;
            padding-left: 2.5rem;
            padding-top: 0.3rem;
            padding-bottom:0.3rem;
            box-shadow: none;
            transition: all 0.2s ease-in-out;
            font-size:0.75rem;
        }

        a:hover {
            background-color: $menu-hover;
        }

        .bi {
            font-size:1.3rem;
            margin-right:0.5rem;
        }
    }

    #nav-footer button {
        width:100%;
        text-align: left;
        background-color: transparent;
        border:none;
        border-radius:0;
        font-size:0.7rem;
    }

    a.active {
        background-color: $menu-active;
        border:none;
    }

    .btn-new-app {
        background-color: transparent;
        border:1px solid gray;
        color:white;

        &:hover{
            background-color: white;
            color: $main-blue;
            border:1px solid white;
        }
    }
}
