@import './icons/style.css';
@import './fonts.scss';

/* Fonts */
$fs-40: 40px;
$fs-36: 36px;
$fs-32: 32px;
$fs-30: 30px;
$fs-28: 28px;
$fs-24: 24px;
$fs-20: 20px;
$fs-18: 18px;
$fs-16: 16px;
$fs-15: 15px;
$fs-14: 14px;
$fs-12: 12px;
$fs-10: 10px;
.fs-60{font-size: 60px;}
.fs-40{font-size: $fs-40;}
.fs-36{font-size: $fs-36;}
.fs-32{font-size: $fs-32;}
.fs-30{font-size: $fs-30;}
.fs-24{font-size: $fs-24;}
.fs-20{font-size: $fs-20;}
.fs-28{font-size: $fs-28;}
.fs-18{font-size: $fs-18;}
.fs-16{font-size: $fs-16;}
.fs-15{font-size: $fs-15;}
.fs-14{font-size: $fs-14;}
.fs-12{font-size: $fs-12;}
.fs-10{font-size: $fs-10;}

$genelead: #1d4eaf;
$bg-main: #fafafa;
$main-color: #1D1D1B;
$body-color: #271E4BCC;
$main-blue: #092776;
$main-secondary:  #6c757d;
$main-warning: #ffc107;
$main-info: #0dcaf0;
$main-hover: #1a76ff17;
$main-danger: red;
$main-success: green;
$text-color: #C0D3FC;
$pre: #000000b3;

$menu-focus: #000000ca;
$menu-hover: #000000af;
$menu-active: #00000075;

body {
    font-family: 'Poppins', sans-serif;
    color: $body-color;
    background-color: $bg-main;
    font-size: 14px;
    font-weight:normal;
}

#app-container {
    padding:0;
    overflow-x: hidden;
}

#content-container {
    padding-bottom:1rem;
    height:100vh;
    overflow-y: scroll;
}

.page-header {
    //padding:0.5rem;
    border-radius:4px;
    padding-top:1rem;

    .action {
        display:flex;
        align-items: center;
        justify-content: flex-end;
    
        .btn:hover {
            background-color: transparent;
            color:$main-color;
        }
    }
}

.badge {
    font-weight: 300;
}

.data-leads {
    padding:0.4rem;
}

#datepicker {
    min-width: 320px;
    border:1px solid #cecece;
    border-radius:4px;
    background-color: white;
    cursor: pointer;
    
    span {
        font-size:1rem;
    }
}

.nav-pills .nav-link.active {
    background-color:$main-blue;
}

pre{
  background-color: $pre;
  color: white;
  padding: 16px;
  border-radius: 4px;
}

@import './auth.scss';
@import './navigation.scss';
@import './table.scss';
@import './buttons.scss';
@import './forms.scss';
@import './filters.scss';