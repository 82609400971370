@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Poppins/Poppins-Regular.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Oswald/Oswald-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Raleway/Raleway-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Lora/Lora-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Lora/Lora-Italic-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Nunito/Nunito-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Nunito/Nunito-Italic-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Roboto/Roboto-Thin.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Roboto/Roboto-Bold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Roboto/Roboto-Italic.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Roboto/Roboto-BoldItalic.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Merriweather/Merriweather-Regular.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Merriweather/Merriweather-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Merriweather/Merriweather-Italic.ttf');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Merriweather/Merriweather-BoldItalic.ttf');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Spectral/Spectral-Regular.ttf');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-display: swap;
    src: url('../../../public/fonts/Spectral/Spectral-Bold.ttf');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Spectral';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Spectral/Spectral-Italic.ttf');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Spectral';
    font-style: italic;
    font-display: swap;
    src: url('../../../public/fonts/Spectral/Spectral-BoldItalic.ttf');
    font-weight: 700;
  }