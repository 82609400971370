.genelead-table {
  background-color: white;

  tbody {
    //border-top: none !important;
  }

  th {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 1rem;
    padding: 0.5rem 0.25rem !important;
    background-color: $main-blue;
    color: white;
    text-align: center;
  }

  th, td {
    vertical-align: middle;
  }

  .badge {
    font-weight: 400;
  }

  th .bi {
    display: none;
  }
}

.genelead-card {
  .card-header {
    background-color: $main-blue;
    color: white;
    font-size: 0.9rem;
  }
}

.category-theme {
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
}

.site-domain-card {
  max-width: 300px;

  .card-header {
    font-size: 18px;
    background-color: $main-blue;
    color: white;
  }
}

.database-button {
  border: 1px solid #cecece;
  padding: 0.3rem;
  border-radius: 4px;

  small {
    word-wrap: break-word;
  }

  .btn-navlink {
    padding: 0.25rem 0.5rem;
  }
}

.reporting-table {
  tbody {
    border-top:1px solid black !important;
  }

  tbody td {
    vertical-align: middle;
    word-wrap: normal;
  }

  thead {
    width: 100%;
    vertical-align: middle;
    background-color: $main-blue !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .pictos {
    th {
      text-align: center !important;
      background-color: $main-blue;
      font-weight: 300;
      color: white;
      padding:0.7rem;
    }
  }

  th a.sort-by {
    text-decoration: none;
    color: white;
    padding-right: 18px;
    position: relative;
    cursor: pointer;
  }

  a.sort-by:before,
  a.sort-by:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: 5px;
    top: 50%;
    position: absolute;
    width: 0;
  }

  a.sort-by:before {
    border-bottom-color: white;
    margin-top: -9px;
  }

  a.sort-by:after {
    border-top-color: white;
    margin-top: 1px;
  }
}