#auth-lp {
  height:100vh;
  background-image: url('../img/login.png');
  background-repeat: no-repeat;
  background-size: cover;

  .row {
    height:100%;
  }

  img {
    max-width:300px;
  }

  a {
    text-decoration: none;
  }

  .auth-card {
    border:none;
    border-radius: 60px;
    padding: 50px;

    h2 {
      text-align:center;
      font-size: 20px;
      margin: 20px 0 16px;
       color: black;
    }

    .logo{
      height: 45px;
      margin-bottom: 14px;
    }

    .card-header {
      text-align: center;
      font-size:$fs-30;
      background-color: transparent;
      border:none;
      padding:1rem;
    }

    label{
      color: black;
      font-weight: 500;
      background-color: white;
      padding: 0 8px;
      transform: translate(10px, 10px);
    }

    .form-control{
      border-radius: 0;
      border-color: $main-blue;
      padding: 12px 16px;

      &::placeholder{
        color: #A3A3A3;
      }
    }

    .btn {
      width:100%;
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 0;
      margin-top: 24px;
    }
  }
}