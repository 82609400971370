$filter-background: transparent;
$filter-color: $main-blue;

.date-picker {
    min-width: 350px;
    .date-picker-wrapper {
        color:$main-color;
        background-color: $filter-background;
        border-radius:4px;
    }
}

.lead-statutes {
    display: flex;
justify-content: end;
  margin: 0;
  padding: 0;

  li {
      button {
        width:100%;
        padding:0.8rem;
      }

      .active {
          background-color: $main-blue !important;
          color:white!important ;
      }
  }
}

.page-header {
    .form-floating.form-control {
        padding:0;
    }
}

.option-wrapper {
    display:flex;
    width:100%;
    border:1px solid $main-hover;
    background-color: $main-hover;
    align-items: center;
    padding:0.5rem;
    margin-top:0.2rem;

    .form-label {
        display:flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
        cursor:pointer;
    }

    small {
        font-size:0.6rem;
    }
}

.filters {
    color:$main-color;

    .form-control, .form-select {
        background-color: white;
        color:$main-color;
    }
}