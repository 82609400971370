.btn-cancel {
    color:white;
}

.btn-lg, a.btn-lg {
    padding:0.1rem 0.4rem;
    //display:inline;
}

.btn-icon {
    display: flex;
    align-items: center;

    .btn-label {
        margin-left:0.2em;
        font-size:0.8em;
    }
}

.btn-padding {
    padding: 0.5rem 1rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
}

.icon-placeholder-border:before {
    content: "\e92e";
    font-size:2.4rem;
}

.icon-label-border:before {
    content: "\e931";
    font-size:2.4rem;
  }

.icon-placeholder-border-bottom:before {
    content: "\e92f";
    font-size:2.4rem;
  }

  .icon-label-border-bottom:before {
    content: "\e930";
    font-size:2.4rem;
  }

.page-header .btn {
    padding: 0.25rem 0.5rem;

    .bi {
        font-size:1.3rem;
        //margin-right:0.3em;
    }
}

.btn-secondary{
    background-color: $main-secondary;
    color:white !important;
}

.btn-secondary:hover {
    color:white !important;
}

.btn-primary {
    background-color: $main-blue;
    border-color:$main-blue;
}

.page-item.active .page-link  {
    background-color: $main-blue !important;
    border-color:$main-blue !important;
}

.btn-primary:hover {
    color:white;
}


.btn-navlink {
    color:white;
}

.alt-primary {
    background-color: transparent !important;
    color: $main-blue !important;
    border-color: $main-blue;
    border:1px solid $main-blue;
}
.alt-primary:hover {
    background-color: $main-blue !important;
    color:white !important;
}
.alt-primary:focus {
    color:$main-blue !important;
}

.alt-secondary {
    background-color: transparent !important;
    color: $main-secondary !important;
    border-color: $main-secondary;
    border:1px solid #cecece;
}

.alt-secondary:hover {
    background-color: $main-secondary !important;
    color: white !important;
}

.alt-danger {
    background-color: transparent !important;
    color:$main-danger;
    border-color:$main-danger;
    border: 1px solid;
}

.alt-success {
    background-color: transparent !important;
    color:$main-success;
    border-color: $main-success;
    border:1px solid;
}

.alt-success:hover {
    color:$main-success !important;
}